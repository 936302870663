<template>

  <div class="cbox">
    <div class="headbar">
      <div style="float: right;">
        <el-button size="small" icon="el-icon-plus" @click="add" type="primary">新增</el-button>
      </div>
      <div style="padding-top: 14px;">
        <el-breadcrumb>
          <el-breadcrumb-item>业务管理</el-breadcrumb-item>
          <el-breadcrumb-item>对接主体管理</el-breadcrumb-item>

        </el-breadcrumb>
      </div>

    </div>
    <div class="bcontent">


      <el-table :data="DataList" stripe border>
        <el-table-column type="index" label="编号" width="80">
        </el-table-column>

        <el-table-column prop="custom_name" label="主体名称" min-width="200">
        </el-table-column>
        <el-table-column prop="username" label="负责人" min-width="200">
        </el-table-column>
        <el-table-column prop="yj_lv" label="佣金成本率" min-width="100">
        </el-table-column>
        <el-table-column prop="jinglr_lv" label="净利润率" min-width="100">
        </el-table-column>
        <el-table-column prop="maolr_lv" label="毛利润率" min-width="100">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">

            <el-button type="text" @click="update(scope.row)">编辑</el-button>
            <el-button type="text" @click="remove(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="编辑主体" :visible.sync="dialogVisible" :close-on-click-modal="false">

      <el-form :model="EditItem" ref="EditItem" label-width="120px">
        <el-row style="padding-top: 0px;">
          <el-col :span="22">
            <el-form-item label="主体名称">
              <el-input v-model="EditItem.custom_name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="padding-top: 0px;">
          <el-col :span="22">
            <el-form-item label="业务员">
              <el-select v-model="EditItem.ywy_id" filterable style="width:100%">
                <el-option v-for="(t, i) in teachers" :key="i" :label="t.username" :value="t.id"></el-option>

              </el-select>

            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="padding-top: 0px;">
          <el-col :span="22">
            <el-form-item label="排序号">
              <el-input v-model="EditItem.sort"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>


      <el-row>
        <el-col :span="22" style="text-align: right;">
          <el-button type="primary" @click="saveForm">保存</el-button>
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      EditItem: {
        show: 1,
        image: "",
        status: true,
        id: 0,
        outurl: "",
        sort: 0
      },
      DataList: [],
      teachers: []

    }
  },
  mounted() {
    this.getList();
    this.getTeachers()
  },
  methods: {
    getList() {
      this.$http.post("/api/sys_kp_company_weblist", {
        page: 1
      }).then(res => {
        this.DataList = res.data

      })
    },
    getTeachers() {
      this.$http.post("/api/t_sch_teachers_list", { pagesize: 200 }).then(res => {
        this.teachers = res.data.data
      })
    },
    update(item) {
      this.dialogVisible = true
      item.status = item.status ? true : false
      this.EditItem = item;
    },
    uploadCover(e) {

      this.EditItem.image = e.src
    },
    add() {
      this.EditItem = {
        image: "",
        status: true,
        id: 0,
        outurl: "",
        sort: 0,
        show: 1
      }
      this.dialogVisible = true
    },
    remove(id) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/sch_customs_delete", {
          id: id
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功'
          });

          this.getList();
        })

      }).catch(() => {

      });
    },
    saveForm() {
      if (this.EditItem.id > 0) {
        this.$http.post("/api/sch_customs_edit", this.EditItem).then(res => {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.dialogVisible = false
          this.getList();
        })
      } else {

        this.$http.post("/api/sch_customs_edit", this.EditItem).then(res => {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.dialogVisible = false
          this.getList();
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
